import React, {useRef, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import "./style.scss";
import {
  agoraJoin,
  agoraLeave,
  getAgoraUserData,
  startBasicCall
} from "../../utils/agora";
import TextChat from "../TextChat/TextChat";
import {
  declOfNum,
  ffirestore,
  ffunctions,
  getRandomDigitFromId,
  getServerTime,
  getUserMetaByUid
} from "../../utils/common";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";
import {
  NO_ACTIVITY_ALERT_DURATION,
  NO_ACTIVITY_DURATION, NOT_ACTIVE_USER_IS_OFFLINE_DELTA,
  ROOM_SPEAKER_TYPE,
  USER_MEMBERSHIP_TYPE
} from "../../utils/const";
import SoundManager from "../SoundManager";
import {Buttons, Instruction} from "../Instruction/Instruction";
import amplitude from "amplitude-js";

class PageVoiceChat extends React.Component {

  constructor(props) {
    super(props);

    this.titleWrapper = React.createRef();

    this.noActivity = {
      timer: null,
      alertTimer: null
    }

    let showInstruction = JSON.parse(localStorage.getItem('showInstruction'));
    if(showInstruction === null)  showInstruction = true;

    this.state = {
      chatIsOpened: false,
      agoraUsers: [],
      users: [],

      countActiveMobileUsers: 0,

      countRemoteUsers: 0,

      showInstruction: showInstruction,

      lastSpeakerSpeaking: null,
      lastSpeakerUid: null,
      lastSpeakerIndex: null,

      prevLastSpeakerSpeaking: null,
      prevLastSpeakerUid: null,
      prevLastSpeakerIndex: null,

      mutedRemoteUsers: []

    }
  }

  getAgoraUser(uid) {
    for(let i=0;i<this.state.agoraUsers.length;i++) {
      if(this.state.agoraUsers[i].uid === uid) {
        return this.state.agoraUsers[i];
      }
    }
    return null;
  }

  async componentDidMount() {

    setTimeout(() => this.props.onShowToast({text: this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.FIXED ? "Ви в кімнаті зі спікером. Підніміть руку, щоб вам дали слово" : "Ви в загальній кімнаті, говоріть, вас чутно"}), 1000)


    amplitude.getInstance().logEvent("enter_to_room", {code: this.props.currentRoom.code, type: this.props.currentRoom.type, time: (new Date).getTime()});
    this.subscriptionRoom = onSnapshot(doc(ffirestore(), `rooms/${this.props.currentRoom.id}`), (doc) => {
      const data = doc.data();

      this.setState({countActiveMobileUsers: data.activeMobileUsers});

      if(data.status === 'closed') {
        this.subscriptionRoom();
        this.subscriptionUsers();
        agoraLeave();
        clearTimeout(this.noActivity.timer);
        clearTimeout(this.noActivity.alertTimer);
        this.props.onShowPopup({
          text: "Кімната була закрита",
          buttons: [{
            text: "Ок",
            callback: () => {
              this.props.onShowPopup(null);
            }
          }]});
        this.props.onLeaveRoom();
      }
    });


    ////////////
    await httpsCallable(ffunctions(), "apiGenerateAgoraToken")({
      roomId: this.props.currentRoom.id,
      userId: localStorage.getItem('deviceId'),
    }).then(async result => {
          window.agoraToken = result.data.rtcToken;
        })
        .catch(error => {
          console.log(error);
        });

    await startBasicCall(() => this.state.mutedRemoteUsers);

    if(this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.ALL) {
      window.agoraApp.client.setClientRole("host");
      await agoraJoin(this.props.currentRoom.id, this.props.firebaseUser.uid, "host", this.props.onShowPopup);
    }

    this.subscriptionUsers = onSnapshot(doc(ffirestore(), `rooms/${this.props.currentRoom.id}/dataCollection/users`), async (doc) => {
      const data = doc.data();


      if(this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.FIXED) {
        const myUserOldData = this.state.users[this.props.firebaseUser.uid];
        const myUserNewData = data.userList[this.props.firebaseUser.uid];

        if(myUserOldData === undefined || myUserOldData.userMembershipType !== myUserNewData.userMembershipType) {
          await agoraLeave();
          let role = myUserNewData.userMembershipType === USER_MEMBERSHIP_TYPE.LISTENER ? "audience" : "host";
          window.agoraApp.client.setClientRole(role);
          await agoraJoin(this.props.currentRoom.id, this.props.firebaseUser.uid, role, this.props.onShowPopup);

          if(myUserOldData && myUserOldData.userMembershipType !== myUserNewData.userMembershipType) {
            if(role === "host") {
              this.props.onShowPopup({
                text: "Спікери дали вам слово. Говоріть",
                buttons: [{text: "Ок", callback: () => {this.props.onShowPopup(null);}}]});
            } else {
              this.props.onShowPopup({
                text: "У вас забрали слово. Дякуємо за ваш спіч",
                buttons: [{text: "Ок", callback: () => {this.props.onShowPopup(null);}}]});
            }
          }

        }
      }

      const user = data.userList[this.props.firebaseUser.uid];
      if(user.name === null) {
        httpsCallable(ffunctions(), "apiChangeUserName")({name: getUserMetaByUid(this.props.firebaseUser.uid, this.props.currentRoom.id, data.userList)}.name);
      }

      this.setState({users: data.userList});
    });

    //////////////


    this._startNoActivityTimer();

    let currentAgoraUsers = [{
      uid: this.props.firebaseUser.uid,
      soundOn: true,
      micOn: true,
      speaking: false
    }];

    window.agoraApp.client.remoteUsers.forEach(user => {
      currentAgoraUsers.push(getAgoraUserData(user));
    })
    this.setState({agoraUsers: currentAgoraUsers});

    ///////////////////////////////////////

    /*window.agoraApp.client.on("client-role-changed", (event) => {
      alert(333);
    });*/

    window.agoraApp.client.enableAudioVolumeIndicator();
    window.agoraApp.client.on("volume-indicator", volumes => {
      this.setState({countRemoteUsers: window.agoraApp.client.remoteUsers.length})
      let foundChanges = false;
      let agoraUsersClone = JSON.parse(JSON.stringify(this.state.agoraUsers));
      let speakingUids = [];
      console.log("!!!!!", window.agoraApp.client.remoteUsers);
      volumes.forEach((volume) => {
        //console.log(`UID ${volume.uid} Level ${volume.level} ${parseFloat(volume.level)}`);
        if(parseFloat(volume.level) >= 60) {
          speakingUids.push(volume.uid);
        }
      });

      let newAgoraUsers = [{
        ...this.state.agoraUsers[0],
        speaking: speakingUids.findIndex(uid => uid === this.state.agoraUsers[0].uid) >= 0
      }];

      window.agoraApp.client.remoteUsers.forEach(remoteUser => {
        newAgoraUsers.push({
          ...getAgoraUserData(remoteUser),
          speaking: speakingUids.findIndex(uid => uid === remoteUser.uid) >= 0
        });
      });



      this.setState({lastSpeakerSpeaking: false});
      this.setState({prevLastSpeakerSpeaking: false});

      if(speakingUids.length === 1) {
        this.setState({lastSpeakerSpeaking: true});
        if(speakingUids[0] !== this.state.lastSpeakerUid) {
          this.setState({
            prevLastSpeakerUid: this.state.lastSpeakerUid,
            prevLastSpeakerIndex: this.state.lastSpeakerIndex,
            lastSpeakerUid: speakingUids[0],
            lastSpeakerIndex: this.getUserIndexByUid(speakingUids[0])
          });
        }
      } else if(speakingUids.length > 1) {
        this.setState({
          prevLastSpeakerSpeaking: true,
          prevLastSpeakerUid: speakingUids[1],
          prevLastSpeakerIndex: this.getUserIndexByUid(speakingUids[1]),
          lastSpeakerSpeaking: true,
          lastSpeakerUid: speakingUids[0],
          lastSpeakerIndex: this.getUserIndexByUid(speakingUids[0])
        });
      }

      this.setState({agoraUsers: newAgoraUsers});
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  getUserIndexByUid(uid) {
    return this.state.agoraUsers.findIndex(user => uid === user.uid);
  }

  leaveRoom(changeRoom = false, showFeedBackForm = false) {
    // стопаем таймер активности
    clearTimeout(this.noActivity.timer);
    agoraLeave();
    this.subscriptionRoom();
    this.subscriptionUsers();
    this.props.onShowPopup(null);
    httpsCallable(ffunctions(), "apiLeaveRoom")({});
    this.props.onLeaveRoom(changeRoom, showFeedBackForm);
  }

  onLeaveRoomClick() {
    amplitude.getInstance().logEvent("exit_popup_opened", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
    this.props.onShowPopup({
      text: "Ви впевнені, що хочете вийти з кімнати? ",
      buttons: [
        {
          text: "Назад",
          callback: () => {
            amplitude.getInstance().logEvent("exit_popup_closed", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
            this.props.onShowPopup(null);
          }
        },
        {
          text: "Вийти",
          callback: () => {
            amplitude.getInstance().logEvent("room_closed", {code: this.props.currentRoom.code, type: this.props.currentRoom.type, time: (new Date).getTime()});

            this.leaveRoom(false, true);
          }
        }
      ]});
  }

  getUserSize(cUsers) {
    //alert(cUsers);
    let userSize = 'size1';
    if(cUsers <= 4) userSize = 'size2';
    else userSize = 'size3';
    return userSize;
  }

  onUserClick = (uid) => {

    if(this.props.firebaseUser.uid === uid) {
      return;
    }

    if(this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.FIXED) {
      // Кейс когда спикер хочет дать слово слушателю
      if(this.state.users[this.props.firebaseUser.uid].userMembershipType === USER_MEMBERSHIP_TYPE.SPEAKER)
      {
        const user = this.state.users[uid];
        if(user) {
          let newUserMembershipType = user.userMembershipType === USER_MEMBERSHIP_TYPE.LISTENER ? USER_MEMBERSHIP_TYPE.TEMP_SPEAKER : USER_MEMBERSHIP_TYPE.LISTENER;

          const userName = getUserMetaByUid(uid, this.props.currentRoom.id, this.state.users).name;
          const toastText = newUserMembershipType === USER_MEMBERSHIP_TYPE.LISTENER ? `Ви більше не будете чути <span>${userName}</span>` : `Ви дали слово <span>${userName}</span>`
          this.props.onShowToast({text: toastText});

          httpsCallable(ffunctions(), "apiChangeUserMembershipType")({
            userMembershipType: newUserMembershipType,
            uid: uid
          });
        }
      }
    } else if(this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.ALL) {
      // Кейс когда в обычной комнате юзер хочет кого-то не слышать
      let foundMutedUserIndex = this.state.mutedRemoteUsers.findIndex(el => el === uid);
      let foundRemoteUser = null;
      for(let i=0;i<window.agoraApp.client.remoteUsers.length;i++) {
        if(window.agoraApp.client.remoteUsers[i].uid === uid) {
          foundRemoteUser = window.agoraApp.client.remoteUsers[i];
          break;
        }
      }
      //-----------------
      if(foundMutedUserIndex !== -1) {
        this.state.mutedRemoteUsers.splice(foundMutedUserIndex, 1);
        if(foundRemoteUser.audioTrack) {
          foundRemoteUser.audioTrack.play();
          amplitude.getInstance().logEvent("user_unmuted", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
        }
      } else {
        this.state.mutedRemoteUsers.push(uid);
        if(foundRemoteUser.audioTrack) {
          foundRemoteUser.audioTrack.stop();
          amplitude.getInstance().logEvent("user_muted", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
        }
      }

      this.setState({mutedRemoteUsers: this.state.mutedRemoteUsers});
    }
  }

  getUsers() {



    const speakers = [];
    const tempSpeakers = [];
    const handUps = [];
    const listeners = [];

    Object.keys(this.state.users).forEach(uid => {
      const user = this.state.users[uid];
      const agoraUser = this.getAgoraUser(uid);

      // не выводим офлайн-юзеров, которые слишком долго офлайн
      if(!user.active && user.updatedTime.seconds * 1000 < getServerTime() - NOT_ACTIVE_USER_IS_OFFLINE_DELTA) {
        return;
      }

      if(user.exit) {
        return;
      }

      const compProps = {
        key: uid,
        uid: uid,
        roomId: this.props.currentRoom.id,
        roomSpeakerType: this.props.currentRoom.speakerType,
        users: this.state.users,
        speaking: agoraUser ? agoraUser.speaking : false,
        micOn: agoraUser ? agoraUser.micOn : true,
        isMuted: this.state.mutedRemoteUsers.findIndex(e => e === uid) !== -1,
        userId: uid,
        onUserClick: this.onUserClick,
        connectedTime: user.connectedTime
      }

      if(user.userMembershipType === USER_MEMBERSHIP_TYPE.SPEAKER) {
        speakers.push(compProps);
      } else if(user.userMembershipType === USER_MEMBERSHIP_TYPE.TEMP_SPEAKER) {
        tempSpeakers.push(compProps);
      } else if(user.userMembershipType === USER_MEMBERSHIP_TYPE.LISTENER) {
        if(user.isHandUp) {
          handUps.push(compProps);
        } else {
          listeners.push(compProps)
        }

      }
    });

    const _sortFunc = (a, b) => a.connectedTime.seconds > b.connectedTime.seconds ? 1 : -1;
    speakers.sort(_sortFunc);
    tempSpeakers.sort(_sortFunc);
    handUps.sort(_sortFunc);
    listeners.sort(_sortFunc);

    let allCompProps = speakers.concat(tempSpeakers, handUps, listeners);
    return allCompProps.map(props => {
      return <VoiceChatUser {...props} size={this.getUserSize(allCompProps.length)}/>
    })
  }

  onTextChatVisibilityChanged(isOpened) {
    this.titleWrapper.current.classList.add("chat-opening");
    this.titleWrapper.current.style.top = this.state.chatIsOpened ? '95rem' : '20rem';
    setTimeout(() => {
      this.titleWrapper.current.classList.remove("chat-opening");
    }, 200)

    this.setState({chatIsOpened: !this.state.chatIsOpened});

  }

  onMicOn(micOn) {
    this.setState({agoraUsers: this.state.agoraUsers.map(user => {
      if(user.uid === this.props.firebaseUser.uid)
        return {...user, micOn: micOn};
      return user;
    })});
  }

  onShareClick = async () => {
    try {
      amplitude.getInstance().logEvent("user_invited", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
      if(navigator.canShare) {
        await navigator.share({
          url: `https://${window.location.hostname}/${this.props.currentRoom.code}?utm_source=voice_chat&utm_medium=invite_link`
        });
      } else {
        this.props.onShowPopup({
          text: "Нажаль, у вас в браузері відключена можливість шерити посилання. Тому просто передайте другу посилання з адресної строки браузера",
          buttons: [{text: "Ок", callback: () => {this.props.onShowPopup(null);}}]});
      }
      //resultPara.textContent = 'MDN shared successfully';
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  }

  onChangeRoomClick = async () => {
    amplitude.getInstance().logEvent("change_room_popup_opened", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
    this.props.onShowPopup({
      text: "Ви впевнені, що хочете змінити кімнату? ",
      buttons: [
        {
          text: "Назад",
          callback: () => {
            amplitude.getInstance().logEvent("change_room_popup_closed", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
            this.props.onShowPopup(null);
          }
        },
        {
          text: "Змінити",
          callback: () => {
            amplitude.getInstance().logEvent("room_changed", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
            this.leaveRoom(true);
          }
        }
      ]});
  }

  onProfileClick = () => {
    amplitude.getInstance().logEvent("name_changing_pop_up", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
    const oldName = getUserMetaByUid(this.props.firebaseUser.uid, this.props.currentRoom.id, this.state.users).name;
    let name = prompt("Змініть ім'я", oldName);
    if(name !== null) {
      if(name !== oldName) {
        amplitude.getInstance().logEvent("name_changed", {code: this.props.currentRoom.code, type: this.props.currentRoom.type});
      }
      name = name.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
      name = name.substring(0, 15).trim();
      //apiChangeUserName
      localStorage.setItem("username", name);
      httpsCallable(ffunctions(), "apiChangeUserName")({name: name});
    }
  }

  onHandButtonClick() {
    const myUserData = this.state.users[this.props.firebaseUser.uid];
    if(myUserData) {
      httpsCallable(ffunctions(), "apiChangeUserHandUp")({
        isHandUp: myUserData.isHandUp === true ? false : true
      }).then(async result => {
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  _startNoActivityTimer() {
    // стартуем таймер контроля активности
    clearTimeout(this.noActivity.timer);
    this.noActivity.timer = setTimeout(() => {

      console.log('$$$ таймер сработал');
      //для спикеров и временных спикеров - не показываем алерт активности
      const myUserData = this.state.users[this.props.firebaseUser.uid];
      if(myUserData && this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.FIXED) {

        if(myUserData.userMembershipType === USER_MEMBERSHIP_TYPE.SPEAKER) {
          // для спикеров стопаем его навсегда
          console.log('$$$ это спикер - прекращаем совсем');
          return;
        } else if(myUserData.userMembershipType === USER_MEMBERSHIP_TYPE.TEMP_SPEAKER) {
          // для временных спикеров перезапускаем его, чтобы он отработал возможно в следующем цикле (когда у юзера отберут слово)
          console.log('$$$ это временніе спикер - откладіваем');
          this._startNoActivityTimer();
          return;
        }
      }
      console.log('$$$ ща будет');

      // юзер неактивен, показываем алерт и стартуем таймер алерта
      SoundManager.play('alert');
      this._startNoActivityAlertTimer();
      this.props.onShowPopup({
        text: "Ви ще там? Якщо ні - через " + NO_ACTIVITY_ALERT_DURATION / 1000 + " секунд ви залишите кімнату",
        countdown: true,
        buttons: [{
          text: "Я тут!",
          callback: () => {
            //console.log('!!!!! this.noActivity.alertTimer cancel');
            // юзер отменяет выход по неактивности
            clearTimeout(this.noActivity.alertTimer);
            this._startNoActivityTimer();
            this.props.onShowPopup(null);
          }
        }]});
    }, NO_ACTIVITY_DURATION);
  }

  _startNoActivityAlertTimer() {
    clearTimeout(this.noActivity.alertTimer)
    this.noActivity.alertTimer = setTimeout(() => {
      console.log('!!!!! this.noActivity.alertTimer');
      this.leaveRoom();
      this.props.onShowPopup({
        text: "Схоже, ви не були активні в чаті, тому вас було видалено з кімнати",
        buttons: [{
          text: "Ок",
          callback: () => {
            this.props.onShowPopup(null);
          }
        }]});
    }, NO_ACTIVITY_ALERT_DURATION);
  }

  render() {
    const myUserData = this.state.users[this.props.firebaseUser.uid];

    const isMyHandUp = myUserData && myUserData.isHandUp;
    const showHandButton = this.props.currentRoom.speakerType === ROOM_SPEAKER_TYPE.FIXED && myUserData && myUserData.userMembershipType === USER_MEMBERSHIP_TYPE.LISTENER

    const usersComponents = this.getUsers();

    return <div className={"page-voice-chat"}>
      {this.state.showInstruction && <Instruction
          onChangeVisible={(visible) => this.setState({showInstruction: visible})}
          code={this.props.currentRoom.code}
          roomType={this.props.currentRoom.type}
      />}
      <div className={"header"}>
        <div className={"exit-wrapper"}>
          <button onClick={() => this.onLeaveRoomClick()} className={"exit"}>Кімната №{this.props.currentRoom.code}<br/>"{this.props.currentRoom.roomTitle}"</button>

          <button className={"profile"} onClick={this.onProfileClick} style={{background: 'url('+getUserMetaByUid(this.props.firebaseUser.uid, this.props.currentRoom.id, this.state.users).avatar+') 0% 0% / cover'}}></button>
          <button onClick={() => this.setState({showInstruction: true})} className={"open-instruction"}></button>
          <button onClick={this.props.onShowTerms} className={"open-terms"}></button>
        </div>

        <div className={"title-wrapper"} ref={this.titleWrapper}>
          <div className={"title"}>{this.props.currentRoom.objectTitle}</div>
          <div className={"count-users"}>{this.state.countActiveMobileUsers} {declOfNum(this.state.countActiveMobileUsers, ['учасник', 'учасники', 'учасників'])}</div>
        </div>

      </div>
      <div className={"users-list"}>
        {usersComponents}
        <InviteUser size={this.getUserSize(usersComponents.length)} onClick={this.onShareClick}/>
        <ChangeRoom size={this.getUserSize(usersComponents.length)} onClick={this.onChangeRoomClick}/>
        <div style={{height: "400rem"}}></div>
      </div>
      <div className={"bottom-shadow"}></div>
      {showHandButton && <HandButton onClick={() => this.onHandButtonClick()} isHandUp={isMyHandUp}/>}
     <TextChat
         onVisibilityChanged={() => this.onTextChatVisibilityChanged()}
         onMicOn={(micOn) => this.onMicOn(micOn)}
         isMicOffVisible={myUserData && myUserData.userMembershipType !== USER_MEMBERSHIP_TYPE.LISTENER}
         currentRoomId={this.props.currentRoom.id}
         currentRoomCode={this.props.currentRoom.code}
         currentRoomType={this.props.currentRoom.type}
         firebaseUser={this.props.firebaseUser}
         users={this.state.users}
         lastSpeakerSpeaking={this.state.lastSpeakerSpeaking}
         lastSpeakerUid={this.state.lastSpeakerUid}
         lastSpeakerIndex={this.state.lastSpeakerIndex}
         prevLastSpeakerSpeaking={this.state.prevLastSpeakerSpeaking}
         prevLastSpeakerUid={this.state.prevLastSpeakerUid}
         prevLastSpeakerIndex={this.state.prevLastSpeakerIndex}
     ></TextChat>
    </div>
  }
}


function VoiceChatUser(props) {

  let isActive = props.users[props.uid] && props.users[props.uid].active;
  let isSpeakerInFixedSpeakersRoom =
      props.roomSpeakerType === ROOM_SPEAKER_TYPE.FIXED &&
      props.users[props.uid] &&
      props.users[props.uid].userMembershipType === USER_MEMBERSHIP_TYPE.SPEAKER;
  let isTempSpeakerInFixedSpeakersRoom =
      props.roomSpeakerType === ROOM_SPEAKER_TYPE.FIXED &&
      props.users[props.uid] &&
      props.users[props.uid].userMembershipType === USER_MEMBERSHIP_TYPE.TEMP_SPEAKER;
  let isHandUp = props.roomSpeakerType === ROOM_SPEAKER_TYPE.FIXED &&
      props.users[props.uid] &&
      props.users[props.uid].isHandUp === true

  const userMeta = getUserMetaByUid(props.uid, props.roomId, props.users);
  return  <div className={"user-wrapper" + (props.isMuted ? " muted" : "")} onClick={() => props.onUserClick(props.uid)}>
    <div className={
        `user ${props.size} 
        ${isActive ? "" : "inactive"} 
        ${props.speaking ? "speaking" : ""}
        ${props.micOn ? "" : "mic-off"}
        ${isHandUp ? "is-hand-up" : ""}
        ${isSpeakerInFixedSpeakersRoom ? "speaker" : ""}`
      }
       style={{background: 'url('+userMeta.avatar+') 0% 0% / cover'}}></div>
    <div className={"name"}>
      {userMeta.name} {isSpeakerInFixedSpeakersRoom ? <span className={"is-speaker"}></span> : ""} {isTempSpeakerInFixedSpeakersRoom ? <span className={"is-temp-speaker"}></span> : ""}
    </div>
  </div>
}

function InviteUser(props) {

  return  <div className={"user-wrapper"} onClick={props.onClick}>
    <div className={"user share " + props.size}></div>
    <div className={"name"}>Додати друга</div>
  </div>
}

function ChangeRoom(props) {

  return  <div className={"user-wrapper"} onClick={props.onClick}>
    <div className={"user change-room " + props.size}></div>
    <div className={"name"}>Зміна кімнати</div>
  </div>
}

function HandButton(props) {

  const [viewLoader, setViewLoader] = useState(false);
  const viewLoaderDelay = 2000;

  return <>
      {viewLoader && <div className={"loader hand-button-loader"}></div>}
      {!viewLoader && <button className={`button hand-button ${props.isHandUp ? "hand-up" : "hand-down"}`} onClick={() => {
          setViewLoader(true);
          setTimeout(() => {
            setViewLoader(false);
          }, viewLoaderDelay);
          props.onClick();
        }}>
        <div className={`icon ${props.isHandUp ? "hand-up" : "hand-down"}`}></div>
        {props.isHandUp ? "Опустити руку" : "Підняти руку"}
      </button>}
  </>
}

export default PageVoiceChat;

