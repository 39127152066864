import React, {useEffect, useRef} from "react";
import "./style.scss";

export function Popup(props) {
  let countdownRef = useRef();
  useEffect(() => {
    //
    if(props.popupData.countdown) {
      setTimeout(() => {
        countdownRef.current.classList.add('run');
      }, 500);
    }
  }, [])
  return <div>
    <div className={"popup-bg"}></div>
    <div className={"popup"}>
      <div className={"text"}>{props.popupData.text}</div>
      {props.popupData.countdown && <div className={"countdown"} ref={countdownRef}></div>}
      <div className={"buttons"}>
        {props.popupData.buttons.map(button => <button
            className={"button button-mint button-inline"}
            onClick={button.callback}
        >{button.text}</button>)}
      </div>

    </div>
  </div>
}
