import React, {useRef, useEffect, useState} from 'react';
import "./style.scss"
import {httpsCallable} from "firebase/functions";
import {declOfNum, ffunctions, getCoderErrorText, getUserMetaByUid, isKeyboardOpen} from "../../utils/common";
import {getAuth} from "firebase/auth";
import NoSleep from "nosleep.js";
import {Instruction} from "../Instruction/Instruction";
import amplitude from "amplitude-js";
import {DEVICE_TYPE, LC_UEFA_LIVE_VOD_OBJECT, ROOM_SPEAKER_TYPE, ROOM_TYPE} from "../../utils/const";


class PageConnect extends React.Component {

  constructor(props) {
    super(props);

    this.codeInput = React.createRef();
    this.loader = React.createRef();



    this.state = {
      pageState: 'hello',
      currentRoomId: null,
      code: '',
      showLoader: false,
      error: '',
      openedRooms: []
    }
  }

  onConnectClick() {

    this.setState({pageState: 'connect-form'});
  }

  onSubmitCodeClick() {
    if(this.codeInput.current.value === "3453463478") {
      httpsCallable(ffunctions(), "apiCreateRoom")({
        objectId: LC_UEFA_LIVE_VOD_OBJECT.objectId,
        objectTitle: LC_UEFA_LIVE_VOD_OBJECT.objectTitle,
        roomType: ROOM_TYPE.STRANGER,
        roomSpeakerType: ROOM_SPEAKER_TYPE.FIXED,
        deviceType: DEVICE_TYPE.TEXTING
      })
      return;
    } else if(this.codeInput.current.value === "3453463479") {
      httpsCallable(ffunctions(), "apiCreateRoom")({
        objectId: LC_UEFA_LIVE_VOD_OBJECT.objectId,
        objectTitle: LC_UEFA_LIVE_VOD_OBJECT.objectTitle,
        roomType: ROOM_TYPE.STRANGER,
        roomSpeakerType: ROOM_SPEAKER_TYPE.ALL,
        deviceType: DEVICE_TYPE.TEXTING
      })
      return;
    }



    this.setState({showLoader: true, error: ''});
    httpsCallable(ffunctions(), "apiJoinMobileToRoomByCode")({
      code: this.codeInput.current.value,
      name: localStorage.getItem('username')
    }).then(async result => {
      //this.setState({currentRoomId: result.data.id})
      await getAuth().currentUser.getIdToken(true);
      this.props.onJoinedRoom(result.data);
      this.setState({showLoader: false, error: ''});
    }).catch(error => {
      amplitude.getInstance().logEvent("code_error");
      let errorText = error.details && error.details.reason ? error.details.reason : 'Невідома помилка';
      this.setState({
        showLoader: false,
        autoConnectRoom: false,
        error: errorText
      });
    })
  }

  componentDidMount() {
    amplitude.getInstance().logEvent("first_page_opened");
    this.setState({
      pageState: 'connect-form',
      code: this.props.code
    });

    if(this.props.autoConnectRoom) {
      setTimeout(() => {
        this.onSubmitCodeClick();
      }, 50);
    } else {
      this.getAllOpenedRoom();
    }
  }

  getAllOpenedRoom() {
    httpsCallable(ffunctions(), "apiGetAllOpenedStrangerRoom")({
      objectId: LC_UEFA_LIVE_VOD_OBJECT.objectId
    }).then(async result => {
      //this.setState({currentRoomId: result.data.id})

      this.setState({openedRooms: result.data.rooms});
    }).catch(error => {

    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.code !== prevProps.code) {
      this.setState({
        code: this.props.code
      })
    }
  }

  handleInput(e) {
    this.setState({code: e.target.value});
  }

  onRoomClick(code) {
    this.setState({code: code});
    setTimeout(() => this.onSubmitCodeClick(), 100);
  }

  /*handleCheckbox(e) {
    this.setState({showInstructions: e.target.checked});
    this.props.onChangeShowInstruction(e.target.checked);
  }*/

  render() {
    return <div className={"page-connect"}>

      {this.props.autoConnectRoom && this.state.error === '' && <ChangeRoomPopup/>}



      <div className={"icon hide-on-too-wide"}>
        <img src={'/img/users.png'}/>
      </div>
      <div className={"title hide-on-too-wide"}>
        Спільний перегляд
      </div>
      <div className={"logo hide-on-too-wide"}>
        <img src={'/img/megogo-logo.png'}/>
      </div>

      <div className={"form"}>
        <div className={"input-label"}>Використайте код для входу</div>
        <input id={"code"} type={"number"} className={this.state.error ? 'wrong' : ''} ref={this.codeInput} value={this.state.code} onChange={(e) => this.handleInput(e)} maxLength={6}/>
        <br/>
        {!this.state.showLoader && <button className={"button-mint button-wide enter"} disabled={this.state.code === ''} onClick={() => this.onSubmitCodeClick()}>Увійти до чату</button>}
        {this.state.showLoader && <div className={"loader"} ref={this.loader}></div>}
        {this.state.error !== '' && <div className={"error"}>{getCoderErrorText(this.state.error)}</div>}
      </div>
      {this.state.openedRooms.length > 0 &&
        <>
          <div className={"rooms-title"}>Доступні публічні кімнати</div>
          <div className={"rooms-list"}>{this.state.openedRooms.map(room => {
              return <div className={`room ${room.speakerType === ROOM_SPEAKER_TYPE.FIXED ? "speaker" : ""}`} onClick={() => this.onRoomClick(room.code)}>
              <div className={"inner-box"}>
              <div className={"room-title"}>{room.roomTitle}</div>
              <div className={"room-subtitle"}>
                <span className={"number"}>№{room.code}</span>
                <span className={"users"}>{room.activeMobileUsers} {declOfNum(room.activeMobileUsers, ['учасник', 'учасники', 'учасників'])}</span>
              </div>
              {room.speakerType === ROOM_SPEAKER_TYPE.FIXED && <div className={"speakers"}>
                {room.speakers.length === 0 ? "Чекаємо на спікерів..." : room.speakers.map(speaker => speaker.name).join(', ')}
              </div>}

            </div>
            </div>

            })}
          </div>
        </>
      }
      {/*<div className={"open-instruction hide-on-too-wide"}><label><input type={"checkbox"} checked={this.props.showInstruction === true} onChange={(e) => this.handleCheckbox(e)}/>Показати інструкцію</label></div>*/}
    </div>
  }
}

function ChangeRoomPopup(props) {

  return  <div className={"change-room-popup"}>
    <div className={"loader"}></div>
    <div className={"text"}>Підбираємо нову кімнату...</div>
  </div>
}

export default PageConnect;
