import React, {useEffect, useRef} from "react";
import "./style.scss";
import {TOAST_TIMEOUT} from "../../utils/const";

export function Toast(props) {
  const toastRef = useRef();
  useEffect(() => {
    /*//
    if(props.popupData.countdown) {
      setTimeout(() => {
        countdownRef.current.classList.add('run');
      }, 500);
    }*/

    setTimeout(() => {
      toastRef.current.classList.add('hide');
      setTimeout(() => props.onHide(), 1000)
    }, TOAST_TIMEOUT);
  }, [])
  return <div>
    <div className={"toast"} ref={toastRef} dangerouslySetInnerHTML={{__html: props.toastData.text}}>
    </div>
  </div>
}
