export const AGORA_APP_ID = '2eca8a309922435eaf760e7b225d132e';

export const NO_ACTIVITY_DURATION = 15 * 60 * 1000;
export const NO_ACTIVITY_ALERT_DURATION = 30 * 1000;

export const DEVICE_TYPE = {
  STREAMING: 'tv',
  TEXTING: 'mobile'
};

export const ROOM_TYPE = {
  PRIVATE: "private",
  STRANGER: "stranger"
}

export const ROOM_SPEAKER_TYPE = {
  ALL: "all",
  FIXED: "fixed"
}

export const USER_MEMBERSHIP_TYPE = {
  SPEAKER: "speaker",
  TEMP_SPEAKER: "temp-speaker",
  LISTENER: "listener"
}

export const NOT_ACTIVE_USER_IS_OFFLINE_DELTA = 120 * 1000;

export const TOAST_TIMEOUT = 3 * 1000;

export const LC_UEFA_LIVE_VOD_OBJECT = {
  objectId: 201,
  objectTitle: "Антверпен - Шахтар"
}

export const AMPLITUDE = {
  APIKEY: "b2253da792c736d1eea1a31ac0b253d0",
  USE_FOR_HOST: [
    //"localhost:3000",
    //"localhost:3001",
    //"localhost:3002",
    "wt.megogo.net"
  ]
};

export const DEFAULT_USER_NAMES = [
    /*'Аарон', 'Агравал', 'Адхибан', 'Вишванатан', 'Теджас', 'Дибьенду', 'Гита', 'Анупама', 'Видит',
    'Абхиджит', 'Притху', 'Рамдас', 'Нилотпал', 'Дивья', 'Гукеш', 'Харика', 'Васанти', 'Джейшри',
    'Рохини', 'Хампи', 'Кришнан', 'Бхакти', 'Абхиджит', 'Бабу', 'Ниша', 'Сайед', 'Нихал', 'Панаяппан',
    'Парамешваран', 'Пентала', 'Деваки', 'Абхиманью',*/

    'Том', 'Джері', 'Леонардо', 'Дональд', 'Сильвестр', 'Арні', 'Девід', 'Шон', 'Дейл', 'Чіп',
    'Стів', 'Райан', 'Донателло', 'Мікеланджело', 'Рафаель', 'Рік', 'Морті', 'Боб', 'Сем', 'Кліф',
    'Енді', 'Джеймс', 'Вуді', 'Джош', 'Джон', 'Воллі', 'Ремі', 'Грегорі', 'Роберт', 'Кріс',
    'Марк', 'Бенедикт'
];




export const DEFAULT_USERS = [
  {
    name: 'Аарон',
    avatar: '/img/avatars/01.png'
  },
  {
    name: 'Агравал',
    avatar: '/img/avatars/02.png'
  },
  {
    name: 'Адхібан',
    avatar: '/img/avatars/03.png'
  },
  {
    name: 'Вішванатан',
    avatar: '/img/avatars/04.png',
  },
  {
    name: 'Бакре',
    avatar: '/img/avatars/05.png'
  },
  {
    name: 'Баруа',
    avatar: '/img/avatars/06.png'
  },

  {
    name: 'Гангули',
    avatar: '/img/avatars/07.png'
  },
  {
    name: 'Гриша баксьор',
    avatar: '/img/avatars/wt13.jpg'
  },
  {
    name: 'Мурзік',
    avatar: '/img/avatars/wt11.jpg'
  },
  {
    name: 'Незнаускас',
    avatar: '/img/avatars/wt0.jpg'
  },
  {
    name: 'Мальвіна',
    avatar: '/img/avatars/wt1.jpg'
  },
  {
    name: 'Йонатан',
    avatar: '/img/avatars/wt3.jpg'
  },
  {
    name: 'Тигр із київскього зооапрку',
    avatar: '/img/avatars/wt7.jpg'
  },
]
