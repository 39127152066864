import AgoraRTC from "agora-rtc-sdk-ng";
import {getFunctions, httpsCallable} from "firebase/functions";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {
  AGORA_APP_ID,
  DEFAULT_USER_NAMES,
  DEFAULT_USERS,
  DEVICE_TYPE,
  LC_UEFA_LIVE_VOD_OBJECT, ROOM_SPEAKER_TYPE,
  ROOM_TYPE
} from "./const";


function getRandomDigitFromId(id) {
  for(let i=0;i<id.length;i++) {
    if(id[i] >= '0' && id[i] <= '9') {
      let n = id[i]-0;
      return n;
    }
  }
  return 0;
}

function getUserMetaByUid(uid, roomId, users) {
  const roomIdCharCode = roomId.charCodeAt(0);
  const countAvatars = 36;
  const step = 17;
  const firstIndex = roomIdCharCode % countAvatars;

  let name = " ";
  let avatarFilename = "empty";

  if(users && !!users[uid]) {
    const index = firstIndex + users[uid].index * step;

    if(users[uid].name) {
      name = users[uid].name;
    } else {
      const nameIndex = index % DEFAULT_USER_NAMES.length;
      name = DEFAULT_USER_NAMES[nameIndex];
    }

    const avatarIndex = index % countAvatars;
    avatarFilename = (avatarIndex + "").padStart(2, "0");
  }

  if(name.length > 10) name = name.substring(0, 10) + "...";

  return {
    name: name,
    avatar: `/img/avatars/${avatarFilename}.png`
  }
}

function ffunctions() {
  return getFunctions(window.firebaseApp, process.env.REACT_APP_region);
}

function ffirestore() {
  return getFirestore(window.firebaseApp)
}

function declOfNum(n, titles) {
  return titles[n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2];
}

function getCoderErrorText(errorCode) {
  if(errorCode === 'wrong-code')  return 'Неправильний пін-код';
  else if(errorCode === 'service-disabled') return 'Голосовий чат відключено'
  return 'Невідома помилка';
}

function isKeyboardOpen(minKeyboardHeight = 200) {
  if(window.visualViewport) {
    console.log(window.screen.height, window.visualViewport.height)
    return window.screen.height - minKeyboardHeight > window.visualViewport.height;
  }
  return false;
}

function balanceUser(objectId, objectTitle, onRoomReceived, onError, lastRoomId = null) {
  //1. Запрашиваем apiGetRandomAvailableStrangerRoom
  httpsCallable(ffunctions(), "apiGetRandomAvailableStrangerRoom")({
    objectId: objectId,
    lastRoomId: lastRoomId
  }).then(async result => {
    if(result.data.room === null) {
      // создаем комнату
      httpsCallable(ffunctions(), "apiCreateRoom")({
        objectId: objectId,

        objectTitle: objectTitle,
        roomType: ROOM_TYPE.STRANGER,
        roomSpeakerType: ROOM_SPEAKER_TYPE.ALL,
        deviceType: DEVICE_TYPE.TEXTING
      }).then(async result => {
        onRoomReceived(result.data.code);
      }).catch(error => {
        if(onError)
          onError(error);
      });
    } else {
      // коннектим юзерв в комнату
      onRoomReceived(result.data.room.code);
    }
  }).catch(error => {
  });
  //2. Если есть - сетим код этой комнаті в стейт
  //3. Если нет - идем на apiCreateRoom, создаем новую комнату и сетим код ее
}

function getServerTime() {
  const delta = window.currentTime.device - window.currentTime.device;
  return new Date().getTime() - delta;
}


export {
  balanceUser,
  getRandomDigitFromId,
  ffunctions,
  ffirestore,
  getUserMetaByUid,
  declOfNum,
  getCoderErrorText,
  isKeyboardOpen,
  getServerTime
}
