import React, {useRef, useEffect, useState} from 'react';
import "./style.scss"
import {httpsCallable} from "firebase/functions";
import {ffunctions, getCoderErrorText, isKeyboardOpen} from "../../utils/common";
import {getAuth} from "firebase/auth";
import NoSleep from "nosleep.js";
import {Instruction} from "../Instruction/Instruction";
import amplitude from "amplitude-js";


class PageTerms extends React.Component {

  constructor(props) {
    super(props);


  }


  componentDidMount() {
    //amplitude.getInstance().logEvent("first_page_opened");

  }

  onBack = () => {
    this.props.onBack();
  }


  render() {
    return <div className={"page-terms"}>
      <div className={"header"}>
        <div className={"title"}>
          <button className={"back"} onClick={this.onBack}></button>
          Правила проведення
        </div>
      </div>

      <div className={"text"}>
        ПРАВИЛА ПРОВЕДЕННЯ
        «Кімнати для спільного перегляду Контенту» (далі – «Правила»)
        <ul>
          <li>«Кімната для спільного перегляду Контенту» (далі по тексту також «Кімната») – це сукупність функціональних можливостей, створених та наданих Організатором, в рамках якої Користувач має змогу через Медіасервіс MEGOGO, що складається із веб-сайтів megogo.net та megogo.ua (далі – «Сайти», а кожен окремо – «Сайт»), програмних додатків MEGOGO (які є невід’ємною частиною Медіасервісу MEGOGO) для різних видів пристроїв: мобільних, Смарт ТБ (Smart TV) та інших, які підключені до мережі Інтернет, здійснити спільний перегляд Контенту, а саме: прямої трансляції фіналу футбольного матчу Ліги націй УЄФА між збірною команди Хорватії та збірною команди Іспанії з футболу, який буде проведений 18.06.2023 року о 21:45 за київським часом (далі – «Фінал футбольного матчу Ліги націй УЄФА») на умовах визначених цими Правилами.</li>
          <li>Організатором є Товариство з обмеженою відповідальністю «Мегого», код ЄДРПОУ 38347009, зареєстроване за адресою: 01011, м. Київ, вул. Рибальська, 22 (Адміністратор Медіасервісу MEGOGO).</li>
          <li>Користувачем може бути будь-яка фізична особа, яка досягла 18-річного віку та володіє необхідним об’ємом дієздатності відповідно до законодавства України, а також яка підключила Передплату «Максимальна» або Передплату «Спорт», та здійснює перегляд трансляцієї Фіналу футбольного матчу Ліги націй УЄФА через Сайт або Програмний додаток MEGOGO, що доступний на різних пристроях, які підключених до мережі Інтернет, а також яка відсканувала розміщений QR-код в студії MEGOGO перед трансляцією або розміщений QR-код під час Фіналу футбольного матчу Ліги націй УЄФА, якщо Організатор також здійснив можливим розміщення QR-коду і під час матчу.</li>
          <li>Кімната для спільного перегляду доступна лише на території України, за винятком тимчасово окупованих територій, а також територій, які перебувають в тимчасовій окупації, оточенні (блокуванні), відповідно до переліку, затвердженого відповідно до чинного законодавства України.</li>
          <li>Інші терміни, не визначені цими Правилами, трактуються відповідно до Угоди Користувача, розміщеної у Медіасервісі MEGOGO, законодавства України та сформованої практики.</li>
          <li>Фінал футбольного матчу Ліги націй УЄФА буде доступний на всіх платформах Медіасервісу MEGOGO (в рамках відповідних Передплат), при цьому можливість спільного перегляду Контенту мають лише ті Користувачі, які відповідають вимогам даних Правил.</li>
          <li>Користувач, який відповідає вищевказаним умовам, має змогу відсканувати QR-код, який буде розміщений в студії MEGOGO перед трансляцією Фіналу футбольного матчу Ліги націй УЄФА або під час трансляції даного матчу, якщо Організатор також здійснив можливим розміщення QR-коду і під час матчу, та після цього потрапити до Кімнати для спільного перегляду Фіналу футбольного матчу Ліги націй УЄФА з іншими Користувачами. В даному випадку, Користувачі які перейшли за посилання QR-коду у вищевказаних Організатором можливих місцях для цього,  зможуть здійснювати спільний перегляду Фіналу футбольного матчу Ліги націй УЄФА в публічних Кімнатах, а також додавати інших Користувачів або змінювати публічну Кімнату.</li>
          <li>Кімната для спільного перегляду Контенту може бути лише публічна. Користувач, який має намір здійснити спільний перегляд, перед скануванням QR-коду в студії MEGOGO або під час трансляції даного матчу (за можливості) та запуском Кімнати має враховувати вищевказані умови, де Користувач має змогу під’єднатися до публічної Кімнати спільного перегляду, де система надає змогу здійснити разом спільний перегляд Контенту з іншими Користувачами. Максимальна кількість Користувачів в одній публічній кімнаті становить 6 (шість) Користувачів. Після досягнення максимальної кількості Користувачів в одній публічній Кімнаті, система генерує створення нової публічної Кімнати з урахуванням вищевказаних умов.</li>
          <li>Функціонал Кімнати для спільного перегляду Контенту доступний в студії MEGOGO перед трансляцією Фіналу футбольного матчу Ліги націй УЄФА, діє під час та зникає по закінченню вказаного матчу.</li>
          <li>Тривалість Кімнати для спільного перегляду є тотожною тривалості Фіналу футбольного матчу Ліги націй УЄФА.</li>
          <li>Інші Користувачі, які відповідають умовам даних Правил, мають змогу підключитися до створеної Кімнати для спільного перегляду також через QR-код для підключення в публічну Кімнату для спільного перегляду Контенту. Обов’язковою умовою доступу до Кімнати для спільного перегляду Контенту є наявність у кожного Користувача такої Кімнати активної (протягом тривалості часу обраного Контенту) Передплати «Максимальна» або Передплати «Спорт» на Медіасервісі MEGOGO.</li>
          <li>Кількість Кімнат для спільного перегляду - необмежені.</li>
          <li>Під час здійснення спільного перегляду Фіналу футбольного матчу Ліги націй УЄФА, Користувачам надається можливість здійснення комунікації та обговорення подій через чат. Чат доступний лише на мобільних телефонах Користувачів. У зв’язку із цим, для надання даної можливості підтримуються наступні мобільні браузери: Chrome, Safari, Opera, Mozilla.</li>
          <li>Користувач зобов'язується у своїй комунікації в рамках функціоналу Кімнати та/або чату дотримуватися встановлених норм моралі, етики та утримуватися від розміщення інформації в текстовій, голосовій або будь-якій іншій формі, яка принижує честь і гідність, порушує права і свободи інших Користувачів та/або третіх осіб, розпалює міжрелігійну, міжрасову, міжетнічну ворожнечу, містить нецензурну лексику, завідомо неправдиву інформацію, та таку, що ганьбить честь і гідність, порушує права інтелектуальної власності третіх осіб або будь-яким іншим способом порушує умови Правил, Угоди користувача або чинного законодавства України. Користувач несе повну відповідальність за свої дії у зв'язку з розміщенням коментарів, в тому числі інформації, яку Користувач поширює про себе та інших Користувачів у Кімнаті, і зобов'язується врегулювати суперечки, претензії, вимоги третіх осіб, пред'явлені у зв'язку з цим, самостійно і за свій рахунок.</li>
          <li>Користувачам, які здійснюють перегляд Контенту через Кімнату для спільного перегляду, буде відображатися QR-код, відсканувавши який Користувачі за допомогою мобільного телефону будуть мати змогу підключитися до спільного чату.</li>
          <li>Після закінчення здійснення спільного перегляду Користувачами Контенту, у випадку якщо Користувачами здійснювалася комунікація та обговорення подій через чат, то повідомлення які були залишені Користувача в чаті, не зберігаються.</li>
          <li>При цьому на всіх Користувачів, які здійснюють спільний перегляд Контенту, розповсюджуються вимоги та обмеження, які встановлені цими Правилами та Угодою Користувача, що розміщена у Медіасервісі MEGOGO.</li>
          <li>Доступ до Кімнати для спільного перегляду Контенту, надається «в тому вигляді, як є», і Організатор не гарантує відповідність Кімнати, Контенту, очікуванням Користувача.</li>
          <li>Організатор залишає за собою право на свій розсуд змінювати і/або доповнювати умови участі в Кімнаті та/або функціональні можливості Кімнати, в будь-який час без будь-якого сповіщення Користувача. Усі зазначені зміни будуть відображені в інтерфейсі Кімнати.</li>
          <li>Організатор має право на свій власний розсуд, незалежно від сповіщення Користувача та без пояснення причин, самостійно обирати Контент відносно якого доступна можливість створення Кімнати.</li>
          <li>У випадку виникнення будь-яких обставин, що перешкоджають створенню чи функціонуванню Кімнати для спільного перегляду Контенту та чату Користувачів– несправності або збою в мережі Інтернет, результатом яких подальше функціонування Кімнати для спільного перегляду Контенту та чату є неможливим, Організатор має право тимчасово призупинити або припинити надання (доступність) даного функціоналу. При цьому, зазначаємо, що Товариський матч доступний на всіх платформах Медіасервісу MEGOGO для Користувачів, які підключили Передплату «Максимальна» або Передплату «Спорт», тому у випадку виникнення несправностей в функціонуванні Кімнати для спільного перегляду Контенту, Користувачі будуть мати змогу повернутися до перегляду Контенту у стандартному вигляді.</li>
          <li>У разі виникнення у Користувача питань, він може задати їх Організатору шляхом направлення електронного листа за адресою support@megogo.net.</li>
          <li>У випадках, що не залежать від Організатора, Організатор не несе відповідальності за будь-які технічні несправності, затримку в обробці або передачі даних, неправомірний доступ третіх осіб до Кімнати для спільного перегляду Контенту. Організатор не гарантує безпомилкову і безперебійну роботу Кімнати для спільного перегляду Контенту і за замовчуванням не несе відповідальності за шкоду, заподіяну Користувачу через технічні збої апаратного або програмного забезпечення, що відбулися не на стороні Організатора та знаходяться поза розумним контролем Організатора.</li>
          <li>Беручи участь у Кімнаті для спільного перегляду Контенту, Користувач тим самим підтверджує факт ознайомлення з даними Правилами і свою повну та безумовну згоду з ними. Порушення Користувачем цих Правил або відмова Користувача від належного виконання цих Правил вважається відмовою Користувача від участі в спільному перегляді Контенту, при цьому така особа не має права на одержання від Організатора будь-якої компенсації.</li>
        </ul>
      </div>
    </div>
  }
}

export default PageTerms;
