import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import amplitude from "amplitude-js";

export function Instruction(props) {

  //const [visible, setVisible] = useState(props.visible);
  const [active, setActive] = useState(0);

  useEffect(() => {
    amplitude.getInstance().logEvent("onboarding_opened", {code: props.code, type: props.roomType});
  }, []);


  return <div>
      <div className={"instruction-bg"}>
      </div>
      <div className={"instruction"}>
        {/*<div className={"title"}>Як приєднатись на Smart TV</div>*/}
        <div className={"close"} onClick={() => {
          amplitude.getInstance().logEvent("onboarding_closed", {code: props.code, type: props.roomType});
          props.onChangeVisible(false);
          localStorage.setItem('showInstruction', false);
        }}></div>

        <Texts active={active}/>
        <Slides active={active}/>
        <Bullets active={active}/>
        <Buttons active={active} next={() => setActive(active+1)} prev={() => setActive(active-1)} onChangeVisible={(visible) => props.onChangeVisible(false)}/>
      </div>
    </div>
}



export function Slides(props) {
  return <div className={`slide slide-${props.active}`}></div>
}

export function Texts(props) {

  return <>
    {props.active === 0 && <div className={"text"}>Щоб запросити друга, <br/> тисніть <span className={"text-icon plus"}></span> та надсилайте посилання в мессенджер</div>}
    {props.active === 1 && <div className={"text"}>Щоб змінити кімнату, <br/> тисніть  <span className={"text-icon refresh"}></span>  і ми підберемо <br/> для вас нову.</div>}
    {props.active === 2 && <div className={"text"}>Зверніть увагу, якщо ви перейшли в іншу вкладку - інші вас не будуть чути.</div>}
  </>
}

export function Bullets(props) {

  return <div className={"bullets"}>
    <div className={`bullet ${props.active === 0 && 'active'}`}></div>
    <div className={`bullet ${props.active === 1 && 'active'}`}></div>
    <div className={`bullet ${props.active === 2 && 'active'}`}></div>
  </div>
}

export function Buttons(props) {

  return <div className={"buttons"}>
    {props.active > 0 && <button className={"button-mint button-inline"} onClick={() => props.prev()}> Назад </button>}
    {props.active < 2 && <button className={"button-mint button-inline"} onClick={() => props.next()}> Далі </button>}
    {props.active == 2 && <button className={"button-mint button-inline"} onClick={() => {
      props.onChangeVisible(false);
      localStorage.setItem('showInstruction', false);
    }}> Закрити </button>}
  </div>
}
