import AgoraRTC from "agora-rtc-sdk-ng";
import {getFunctions, httpsCallable} from "firebase/functions";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import {AGORA_APP_ID} from "./const";
import {ffunctions} from "./common";

//

async function startBasicCall(getMutedRemoteUsers) {
  window.agoraApp = {
    localAudioTrack: null,
    client: null,
    sound0: false
  };
  //window.agoraApp.Logger.setLogLevel(AgoraRTC.Logger.NONE);
  // Create an AgoraRTCClient object.
  window.agoraApp.client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

  // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
  window.agoraApp.client.on("user-published", async (user, mediaType) => {
    // Subscribe to the remote user when the SDK triggers the "user-published" event
    await window.agoraApp.client.subscribe(user, mediaType);
    console.log("subscribe success");

    // If the remote user publishes an audio track.
    if (mediaType === "audio") {
      // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
      const remoteAudioTrack = user.audioTrack;
      // Play the remote audio track.
      //remoteAudioTrack.setVolume(window.agoraApp.sound0 ? 0 : 50);
      if(!window.agoraApp.sound0) {
        const mutedRemoteUsers = getMutedRemoteUsers();
        if(mutedRemoteUsers.findIndex(el => el === user.uid) === -1) {
          remoteAudioTrack.play();
        } else {
          remoteAudioTrack.stop();
        }
      }
    }

    // Listen for the "user-unpublished" event
    window.agoraApp.client.on("user-unpublished", async (user) => {
      // Unsubscribe from the tracks of the remote user.
      await window.agoraApp.client.unsubscribe(user);
    });

  });
}

async function agoraJoin(roomId, uid, role, onShowPopup) {
  console.log('!!!!!AGORA JOIN');
  await window.agoraApp.client.join(AGORA_APP_ID, roomId, window.agoraToken, uid);
  if(role === "host") {
    try {
      window.agoraApp.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await window.agoraApp.client.publish([window.agoraApp.localAudioTrack]);
    } catch (e) {
      console.log('!!!!! Agora error: ', e);
      onShowPopup({
        text: "Схоже у вас в браузері не дозволено користуватися мікрофоном. Перевірте дозволи в браузері для цього сайту і після цього перезайдіть в кімнату",
        buttons: [{text: "Ок", callback: () => {onShowPopup(null);}}]});
    }
  }
}

async function agoraLeave() {
  console.log('!!!!!AGORA LEAVE');
  if(window.agoraApp.localAudioTrack) {
    console.log('!!!!!AGORA LEAVE stope');
    window.agoraApp.localAudioTrack.stop();
    window.agoraApp.localAudioTrack.close();
  }

  await window.agoraApp.client.leave();
}

function getAgoraUserData(user) {
  return {
    uid: user.uid,
    soundOn: user._audio_muted_,
    micOn: !user._audio_muted_,
    speaking: false
  }
}



export {
  startBasicCall,
  getAgoraUserData,
  agoraLeave,
    agoraJoin
}
