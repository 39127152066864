import {SoundSource} from "../utils/soundEffect";

class SoundManager {

  constructor() {

    this.audioContext = null;
    this.source = null;
    this.soundsLoaded = false;
    this.tooEarlyPlayBuffer = [];

    /*this.playTooEarlyPlayBuffer = reaction(
        () => this.soundsLoaded,
        soundsLoaded => {
          this.tooEarlyPlayBuffer.map(act => this.playPls(act));
        }
    );*/

    let sounds = [
      {act: "alert",              url: "/sounds/alert.mp3"},
      {act: "exit",              url: "/sounds/exit.mp3"}
    ];


    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();


    this.source = new SoundSource(this.audioContext, sounds, () => {
      console.log("Finished loading sounds!");
      this.soundsLoaded = true;
      //this.playPls("background-music");

    });

    /*this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);

    this.gainNode.gain.value = -1;*/

    console.log('Sounds Stored!!!');

  }

  play(act) {
    if(this.soundsLoaded) {

      if (this.audioContext.state === 'suspended') {
        let audio = this.source;
        this.audioContext.resume().then(function () {
          audio.start(act);
        });
      }
      else
        this.source.start(act);
    } else {
      console.log('!@! too early');
      this.tooEarlyPlayBuffer.push(act);
    }
  }
  }

export default new SoundManager();
