import React, {useEffect, useRef} from "react";
import "./style.scss";

export function AlbumPopup(props) {
  let countdownRef = useRef();
  useEffect(() => {

  }, [])


  return <div>
    <div className={"album-popup-bg"}></div>
    <div className={"album-popup"}>
      <div>
        <div className={"rotate-icon"}></div>
      </div>
      <div>
        <div className={"text"}>Чат спільного перегляду на Megogo доступний лише на мобільному пристрої з портретною орієнтацією екрану. Будь ласка, відкрийте посилання на вашому телефоні</div>
      </div>
    </div>
  </div>
}
