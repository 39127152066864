import React, {useRef, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import "./style.scss";
import userEvent from "@testing-library/user-event";
import {ffirestore, ffunctions, getRandomDigitFromId, getUserMetaByUid} from "../../utils/common";
import {doc, onSnapshot} from "firebase/firestore";
import {httpsCallable} from "firebase/functions";
import amplitude from "amplitude-js";

class TextChat extends React.Component {

  constructor(props) {
    super(props);

    this.textChat = React.createRef();
    this.messageInputRef = React.createRef();
    this.chatHeader = React.createRef();
    this.chatMain = React.createRef();

    this.touchY = 0;
    this.deltaY = 0;

    this.subscription = null;

    this.state = {
      isOpened: false,
      soundOn: true,
      micOn: true,
      chatMessageGroups: [],
      messagesCount: 0,
      messagesCountLastViewed: 0
    }
  }





  componentDidMount() {

    this.subscription = onSnapshot(doc(ffirestore(), `rooms/${this.props.currentRoomId}/dataCollection/textChat`), (doc) => {
      let messages = [];
      let data = doc.data();
      Object.keys(data.messages).forEach(messageId => {
        messages.push({messageId, ...data.messages[messageId]});
      });

      //console.log(messages[0].createdTime.seconds);
      messages.sort((a, b) => {
        return a.createdTime.seconds > b.createdTime.seconds ? 1 : -1;
      })


      let messageGroups = [];
      for(let i=0;i<messages.length;i++) {
        if(messageGroups.length === 0) {
          messageGroups.push([]);
        }
        else {
          if(messageGroups[messageGroups.length-1][0].uid !== messages[i].uid) {
            // новая группа
            messageGroups.push([]);
          }
        };
        messageGroups[messageGroups.length-1].push(messages[i]);
      }

      this.setState({
        messagesCount: messages.length,
        chatMessageGroups: messageGroups
      });

      if(this.state.isOpened) {
        this.setState({
          messagesCountLastViewed: messages.length
        });
      }

    });

    window.addEventListener('resize', () => this.onWindowResize());

    this.chatMain.current.scrollTop = this.chatMain.current.scrollHeight;


    ///////////////
    this.chatHeader.current.addEventListener('touchstart', (e) => {

      //clientX = e.touches[0].clientX;
      //clientY = e.touches[0].clientY;
      console.log('touchstart', e.touches[0].clientX, e.touches[0].clientY);
      this.touchY = e.touches[0].clientY;
      this.deltaY = 0;

    }, false);

    this.chatHeader.current.addEventListener('touchmove', (e) => {

      const deltaY = e.touches[0].clientY - this.touchY;
      if(!this.state.isOpened) {
        if(deltaY < 0) {
          this.deltaY = deltaY;
          const newY = -785 - this.deltaY;
          this.textChat.current.style.bottom = newY + 'rem';
        }
      } else {
        if(deltaY > 0) {
          this.deltaY = deltaY;
          const newY = 0 - this.deltaY;
          this.textChat.current.style.bottom = newY + 'rem';
        }
      }

    }, false);
    this.chatHeader.current.addEventListener('touchend', (e) => {

      this._animateOpenClose();

    }, false);
  }

  addLocalMessage(text) {
    let newChatMessageGroups = [...this.state.chatMessageGroups];

    if(newChatMessageGroups.length) {
      let lastMessageGroup = newChatMessageGroups[newChatMessageGroups.length-1];
      console.log(lastMessageGroup);
      if(lastMessageGroup[0].uid !== this.props.firebaseUser.uid) {
        newChatMessageGroups.push([]);
      }
    } else {
      newChatMessageGroups.push([]);
    }

    let lastMessageGroup = newChatMessageGroups[newChatMessageGroups.length-1];
    lastMessageGroup.push({
      text: text,
      uid: this.props.firebaseUser.uid,
      sending: true
    });

    this.setState({chatMessageGroups: newChatMessageGroups});

  }

  _animateOpenClose() {
    this.textChat.current.classList.add('opening');
    setTimeout(() => this.textChat.current.classList.remove('opening'), 300);

    let newY = 0;
    if(Math.abs(this.deltaY) > 100) {
      this.onOpenClose();
      if(!this.state.isOpened) newY = 0;
      else newY = -785;
    } else {
      if(!this.state.isOpened) newY = -785
      else newY = 0;
    }

    this.textChat.current.style.bottom = newY + 'rem';
  }

  onOpenCloseClick() {
    this.deltaY = 9999;
    this._animateOpenClose();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
    this.subscription();
    window.removeEventListener('resize', this.onWindowResize);
  }

  changeTextChatAppearance() {
    const h_w = window.innerHeight / window.innerWidth;
    const html = document.querySelector('html');
    if(h_w <= 1.5) {

      const h = window.innerHeight - (html.getAttribute('fs')-0) * 320;
      document.querySelector('.text-chat .main').style.height =   h+'px';
    } else {
      document.querySelector('.text-chat .main').style.height = '680rem';
    }
  }

  onWindowResize() {
    this.changeTextChatAppearance();
  }


  onOpenClose() {

    // в некоторых браузерах что-то не то с событием resize когда выезжает-прячется клавиатура
    // поэтому дополнительно при показе-скрытии чата также меняем вид чата
    this.changeTextChatAppearance();

    const newIsOpened = !this.state.isOpened;
    this.setState({isOpened: newIsOpened});
    this.props.onVisibilityChanged(newIsOpened);
    this.setState({messagesCountLastViewed: this.state.messagesCount});

    this.chatMain.current.scrollTop = this.chatMain.current.scrollHeight;
  }

  onSoundClick() {

    for(let i=0;i<window.agoraApp.client.remoteUsers.length;i++) {

      if(window.agoraApp.client.remoteUsers[i].audioTrack) {
        if(this.state.soundOn)
          window.agoraApp.client.remoteUsers[i].audioTrack.stop();
        else
          window.agoraApp.client.remoteUsers[i].audioTrack.play();
        //window.agoraApp.client.remoteUsers[i].audioTrack.setVolume(this.state.soundOn ? 0 : 50);
      } else {
      }

    }
    window.agoraApp.sound0 = this.state.soundOn;
    this.setState({soundOn: !this.state.soundOn});
  }

  onMicClick() {
    if(window.agoraApp.localAudioTrack) {
      window.agoraApp.localAudioTrack.setMuted(this.state.micOn);
    }

    this.props.onMicOn(!this.state.micOn);
    this.setState({micOn: !this.state.micOn});

  }

  onSendMessageClick() {
    if(this.messageInputRef.current.value.trim() !== '') {
      if(this.messageInputRef.current.value[0] !== '$')
        this.addLocalMessage(this.messageInputRef.current.value);
      setTimeout(() => this.chatMain.current.scrollTop = this.chatMain.current.scrollHeight, 10);
      amplitude.getInstance().logEvent("text_message_sent", {code: this.props.currentRoomCode, type: this.props.currentRoomType});
      httpsCallable(ffunctions(), "apiSendChatTextMessage")({
        text: this.messageInputRef.current.value
      });
      this.messageInputRef.current.value = '';
    }
  }

  getMessageGroupsItems() {
    let groups = this.state.chatMessageGroups.map(messageGroup => <div className={"message-series-" + (messageGroup[0].uid === this.props.firebaseUser.uid ? 'my' : 'his')}>
      {messageGroup.map((message, index) =>
          <div className={"message" + (message.sending ? ' sending' : '')}>
            {index === messageGroup.length-1 && <div className={"avatar"} style={{background: 'url('+getUserMetaByUid(message.uid, this.props.currentRoomId, this.props.users).avatar+') 0% 0% / cover'}}></div>}
            {index === 0 && messageGroup[0].uid !== this.props.firebaseUser.uid && <div class={"author"}>{getUserMetaByUid(message.uid, this.props.currentRoomId, this.props.users).name}</div>}
            {message.text}
          </div>
      )}
    </div>);

    return groups;
  }



  render() {
    console.log('###', this.state.messagesCount, this.state.messagesCountLastViewed);
    //console.log(this.props);
    const isMicOffVisible = true;
    const newMessagesCount = this.state.messagesCount - this.state.messagesCountLastViewed;
    return <div ref={this.textChat} className={"text-chat "}>

      <div className={"header"} ref={this.chatHeader}>
        {this.props.isMicOffVisible && <button className={this.state.micOn ? 'pressed mic-on' : 'mic-off'} onClick={() => {
          amplitude.getInstance().logEvent("mic_turned", {code: this.props.currentRoomCode, type: this.props.currentRoomType});
          this.onMicClick();
        }}></button>}
        <button className={this.state.soundOn ? 'pressed sound-on' : 'sound-off'} onClick={() => {
          amplitude.getInstance().logEvent("sound_turned", {code: this.props.currentRoomCode, type: this.props.currentRoomType});
          this.onSoundClick()
        }}></button>
        <div className={"swipe"}></div>
        {(!this.state.isOpened || !this.props.prevLastSpeakerUid && !this.props.lastSpeakerUid) && <button className={"chat"} onClick={() => this.onOpenCloseClick()}>{newMessagesCount > 0 && <div class={"has-new-message"}>{newMessagesCount <= 9 ? newMessagesCount : "9+"}</div>}</button>}
        {(this.state.isOpened && this.props.prevLastSpeakerUid)  && <button className={"last-speaker" + (this.props.prevLastSpeakerSpeaking ? ' speaking' : '')} style={{background: 'url('+getUserMetaByUid(this.props.prevLastSpeakerUid, this.props.currentRoomId, this.props.users).avatar+') 0% 0% / cover'}}></button>}
        {(this.state.isOpened && this.props.lastSpeakerUid)      && <button className={"last-speaker" + (this.props.lastSpeakerSpeaking ? ' speaking' : '')} style={{background: 'url('+getUserMetaByUid(this.props.lastSpeakerUid, this.props.currentRoomId, this.props.users).avatar+') 0% 0% / cover'}}></button>}
      </div>
      <div className={"main"} ref={this.chatMain}>
        <div className={"message-list"}>
          {this.getMessageGroupsItems()}

        </div>
      </div>

      <div className={"footer"}>
        <table width={"100%"}>
          <tr>
            <td>
              <input ref={this.messageInputRef} className={"message-input"} placeholder={"Текст повідомлення"}/>
            </td>
            <td className={"send-message-td"}>
              <button className={"send-message"} onClick={() => this.onSendMessageClick()}></button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  }
}

export default TextChat;

