import logo from './logo.svg';
import './App.scss';
import {getFunctions, connectFunctionsEmulator, httpsCallable} from 'firebase/functions';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, onAuthStateChanged } from "firebase/auth";

import React from "react";

import PageConnect from "./components/PageConnect/PageConnect";
import PageVoiceChat from "./components/PageVoiceChat/PageVoiceChat";
import {Popup} from "./components/Popup/Popup";
import SoundManager from "./components/SoundManager";
import {AlbumPopup} from "./components/AlbumPopup/AlbumPopup";
import {balanceUser, ffunctions} from "./utils/common";
import amplitude from 'amplitude-js';
import {AMPLITUDE, DEVICE_TYPE, LC_UEFA_LIVE_VOD_OBJECT, ROOM_TYPE} from "./utils/const";
import PageTerms from "./components/PageTerms/PageTerms";
import {Toast} from "./components/Toast/Popup";
import NoSleep from "nosleep.js";

const { v4: uuidv4 } = require('uuid');

window.firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
});

if(window.location.host.split(':')[0] === 'localhost')
  connectFunctionsEmulator(ffunctions(), "localhost", 5001);

if(!!AMPLITUDE.USE_FOR_HOST.find(el => el === document.location.host)) {
  amplitude.getInstance().init(AMPLITUDE.APIKEY);
}


/*const agoraOptions = {
  appId: "4390346caf6844319b5fd650d3ba18c9",
  channel: "555",
  token: "007eJxTYBC5OMv+K2fJbwnRmJNlGm/PHfjwrHnWircHdriHz7VatjVZgcHE2NLA2MQsOTHNzMLExNjQMsk0LcXM1CDFOCnR0CLZ0q5WI3nPOc3krFnfGRkZIBDEZ2YwNTVlYAAA2YIigw==",
  uid: null
};*/



class App extends React.Component {

  constructor(props) {
    super(props);


    let deviceId = localStorage.getItem("deviceId");
    if(!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceId", deviceId);
    }

    this.noSleep = new NoSleep();

    this.state = {
      deviceId: deviceId,
      firebaseUser: null,
      currentRoom: null,
      popupData: null,
      toastData: null,
      code: "",
      roomType: ROOM_TYPE.PRIVATE,
      height: 0,
      width: 0,
      documentHidde: false,
      showTerms: false
    }

  }

  setFS() {
    const fs = window.innerHeight / 1000;
    const html = document.querySelector('html');
    html.style.fontSize = fs + 'px';
    html.setAttribute('fs', fs+'');
  }

  resize() {
    const h_w = window.innerHeight / window.innerWidth;
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
    const html = document.querySelector('html');

    if(h_w > 1.3) { //портрет
      this.setFS();
      html.removeAttribute('too-wide');
      html.removeAttribute('album');
    } else if (h_w > 0.75) { // портрет но открыта клавиатура
      html.setAttribute('too-wide', "1");
      html.removeAttribute('album');
    } else { // альбом
      this.setFS();
      html.removeAttribute('too-wide');
      html.setAttribute('album', "1");
      //amplitude.getInstance().logEvent("width_popup_opened");
    }

  };

  componentDidMount() {

    this.setFS();
    window.addEventListener('resize', () => setTimeout(() => this.resize(), 50));
    window.addEventListener('visibilitychange', () => {
      //alert(document.hidden);
      this.setState({documentHidden: document.hidden});
      if(this.state.currentRoom) {
        httpsCallable(ffunctions(), "apiChangeUserActive")({
          active: !document.hidden
        }).then(async result => {
        }).catch(error => {
        });
      }
    });

    window.addEventListener("beforeunload", (event) => {
      httpsCallable(ffunctions(), "apiChangeUserActive")({
        active: false
      }).then(async result => {
      }).catch(error => {
      });
      return false;
    });

    this.resize();


    console.log('window.location.pathname', window.location.pathname);
    if(window.location.pathname[0] === '/' && window.location.pathname.length > 1) {
      if(window.location.pathname.substring(1) !== "stranger") {
        this.setState({code: window.location.pathname.substring(1)});
      }
    }

    const auth = getAuth();
    ///////////
    httpsCallable(ffunctions(), "apiGetCustomToken")({
      deviceId: this.state.deviceId
    }).then(async result => {
      const token = result.data.token;
      signInWithCustomToken(auth, token).then((userCredential) => {
      })
      .catch((error) => {
      });
    }).catch(error => {
    });
    ///////////


    onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged', user);
      if (user) {
        this.setState({firebaseUser: user});
        if(window.location.pathname.substring(1) === "stranger") {
          balanceUser(LC_UEFA_LIVE_VOD_OBJECT.objectId, LC_UEFA_LIVE_VOD_OBJECT.objectTitle,
              (code) => {
            this.setState({
              code: code,
              roomType: ROOM_TYPE.STRANGER
            })},
            (error) => {
              if(error.details && error.details.reason) {
                if(error.details.reason === 'service-disabled') {
                  alert("Голосовий чат відключено");
                }
              }
            }
          )
        }
      } else {
        this.setState({firebaseUser: null});
      }
    });
  }

  onJoinedRoom(data) {
    this.noSleep.enable();
    this.setState({
      currentRoom: {
        id: data.roomId,
        ...data.room
      },
    });
    window.currentTime = {
      server: data.serverTime,
      device: new Date().getTime()
    }
  }

  onLeaveRoom(changeRoom, showFeedBackForm) {
    this.noSleep.disable();
    if(changeRoom) {
      //this._pushToStrangerRoomsHistory(this.state.code)
      let lastRoomCode = this.state.code;
      /*console.log('### apiGetRandomAvailableStrangerRoom onLeaveRoom');
      httpsCallable(ffunctions(), "apiGetRandomAvailableStrangerRoom")({
        objectId: LC_UEFA_LIVE_VOD_OBJECT.objectId,
        lastRoomId: this.state.currentRoom.id
      }).then(async result => {
        if(result.data.room === null) {*/
          balanceUser(LC_UEFA_LIVE_VOD_OBJECT.objectId, LC_UEFA_LIVE_VOD_OBJECT.objectTitle,
(code) => {
            if(lastRoomCode === code) {
              amplitude.getInstance().logEvent("no_room_available", {time: (new Date).getTime()});
              this.setState({
                popupData: {
                  text: "Не вдалось знайти нову кімнату. Спробуйте пізніше.",
                  buttons: [
                    {
                      text: "Ок",
                      callback: () => {
                        this.setState({popupData: null});
                      }
                    }
                  ]
                }
              })
            }

            this.setState({
              code: code,
              roomType: ROOM_TYPE.STRANGER,
              autoConnectRoom: true,
              currentRoom: null,
            });
          },
          (error) => {
            if(error.details && error.details.reason) {
              if(error.details.reason === 'service-disabled') {
                alert("Голосовий чат відключено");
              }
            }}
          , this.state.currentRoom.id)
        /*} else {
          // коннектим юзерв в комнату
          this.setState({
            code: result.data.room.code,
            roomType: ROOM_TYPE.STRANGER,
            autoConnectRoom: true,
            currentRoom: null
          });
          setTimeout(() => {

          }, 50);
        }
      }).catch(error => {
      });*/
    } else {

      if(showFeedBackForm) {
        /*setTimeout(() => {
          let text = prompt('Поділіться вашим досвідом використання голосового чату MEGOGO');
          if(!!text) {
            httpsCallable(ffunctions(), "apiSendFeedback")({
              text: text,
              roomCode: this.state.code
            }).then(async result => {
            }).catch(error => {
            });
          }
        }, 1000);*/
      }

      this.setState({
        autoConnectRoom: false,
        currentRoom: null,
        strangerRoomsHistory: []
        //code: ""
      });
    }

    SoundManager.play('exit');

  }

  shouldComponentUpdate(props, state) {
    //if(state.showTerms)
    if(state.showTerms !== this.state.showTerms) {
      if(state.showTerms) {
        document.body.style.overflow = "scroll";
      } else {
        document.body.style.overflow = "hidden";
      }
    }
    return true;
  }

  render() {
    return <div className="App">

      <AlbumPopup/>

      {!this.state.firebaseUser && <div className={"loader-popup"}></div>}



      {this.state.firebaseUser && !this.state.currentRoom && <PageConnect
          code={this.state.code}
          autoConnectRoom={this.state.autoConnectRoom}
          onJoinedRoom={(data) => this.onJoinedRoom(data)}
          onShowTerms={() => this.setState({showTerms: true})}
      />}
      {this.state.firebaseUser && this.state.currentRoom && <PageVoiceChat
          currentRoom={this.state.currentRoom}
          currentRoomData={this}
          firebaseUser={this.state.firebaseUser}
          onLeaveRoom={(changeRoom, showFeedBackForm) => this.onLeaveRoom(changeRoom, showFeedBackForm)}
          onShowPopup={(popupData) => {this.setState({popupData: popupData})}}
          onShowToast={(toastData) => {this.setState({toastData: toastData})}}
          onShowTerms={() => this.setState({showTerms: true})}
      />}

      {this.state.showTerms && <PageTerms onBack={() => {
        this.setState({showTerms: false})
      }
      }/>}

      {this.state.popupData && <Popup popupData={this.state.popupData}/>}
      {this.state.toastData && <Toast toastData={this.state.toastData} onHide={() => this.setState({toastData: null})}/>}
    </div>
  }
}


export default App;
